import React from 'react'
import CorrelationPlot from '../components/CorrelationPlot';

function Visualizations() {
  return (
    <div>
        <CorrelationPlot />
    </div>
  )
}

export default Visualizations