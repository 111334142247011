import React from 'react'
import CorrelationTable from '../components/CorrelationTable';


function Data() {
  return (
    <div>
        <CorrelationTable />
    </div>
  )
}

export default Data