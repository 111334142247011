// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #eee;
    height: 60px;
}

.logo-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-logo{
    max-width: 200px;
}

.nav-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.nav-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style-type: none;
    width: 500px;
}

.header-underline:hover{
    color: #040c84;
}

.header-underline {
    text-decoration: none;
    transition: all 300ms ease;
    font-size: 20px;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,sBAAsB;IACtB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,0BAA0B;IAC1B,eAAe;IACf,YAAY;AAChB","sourcesContent":[".header{\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    padding: 10px 0 10px 0;\r\n    border-bottom: 1px solid #eee;\r\n    height: 60px;\r\n}\r\n\r\n.logo-wrapper{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.nav-logo{\r\n    max-width: 200px;\r\n}\r\n\r\n.nav-wrapper{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-around;\r\n}\r\n\r\n.nav-list {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-around;\r\n    list-style-type: none;\r\n    width: 500px;\r\n}\r\n\r\n.header-underline:hover{\r\n    color: #040c84;\r\n}\r\n\r\n.header-underline {\r\n    text-decoration: none;\r\n    transition: all 300ms ease;\r\n    font-size: 20px;\r\n    color: black;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
